import {PwaVersions} from "../../utils/enums"
import CardAndBalance from "./Components/CardAndBalance/CardAndBalance"
import CardSummary from "./Components/CardSummary/CardSummary"
import { Header } from "../core/Header/Header"
import Offers from "./Components/Offers/Offers"
import Transaction from "./Components/Transaction/Transaction"
import  "./home.css"
import 'react-toastify/dist/ReactToastify.css';
import {  useSelector } from "react-redux"
import SmartNudges from "./Components/SmartNudges"
import { Toast, showToastMessage } from "../toast/Toast"
import { activateCard, cardInHand } from "../../assets/images"

const Home = () => {

  const [home] = useSelector(state => [state.Home])

  const versioned_home = () => {
    const config = {version : PwaVersions.V2}
    switch (config.version) {
      default :
      case PwaVersions.V1:
        return (
            <div>Pwa v1</div>
        )
      case PwaVersions.V2:
        return (
        <div className="home">
            <Header headerTitle={"My Card"}/>
            <div className="p-3">
              <div className="mt-2">
                { home.cardConfigSection.isQrCodeRequiredForActivation ? <SmartNudges title="Activate Physical card" url={cardInHand} subtitle="Enable offline usage of you credit card" />
                  : <SmartNudges title="Enable Offline Transactions" url={activateCard} subtitle="Update Card Controls to transact offline" />}
              </div>
              <div  className="home-cardAndBalance mt-1">
                  <CardAndBalance/>
              </div>
              {home.offersAndPromotionsSection?.isOffersEnabled && <div className="home-offers">
                  <Offers/>
              </div>}
              <div className="home-cardSummary">
                  <CardSummary/>
              </div>
              {home.transactionSection.isTransactionEnabled && <div className="home-transactions">
                  <Transaction/>
              </div>}
            </div>
        </div>
        )
    }
  }
  const showMessage = () => {
    showToastMessage()
  };

  return (
    <>
    
        {versioned_home()}
    </>
  )
}

export default Home
