import React from 'react'
import { verticalCard } from '../../../assets/images';
import { Header } from '../../core/Header/Header'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { DEFAULT_APP_TEXT_COLOR, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling';

function EnterMpin() {

    const [colorSchema,preLogin] = useSelector(state => [state.ColorSchema,state.PreLogin])
  return (
    <div style={{fontFamily:'Inter'}}>
        <Header headerTitle="Login" />
        <div className='mx-3 mt-4'>
        <div className='p-4' style={{backgroundColor:"#FFF",boxShadow:"0px 0px 2px gray",borderRadius:'8px',color: colorSchema.background.textColor || DEFAULT_APP_TEXT_COLOR}}>
                <div>
                    <div className='header' style={{fontWeight:'600',fontSize:'18px'}}>
                        Enter MPIN
                    </div>
                    <div className='' style={{fontSize:'12px'}}>Please enter your MPIN to proceed</div> 
                </div>
                <div className='mt-3'>
                    <div className='input-container d-flex align-items-center justify-content-between pt-2'>
                        {
                            [1,2,3,4].map((el,index) => <input key={index} type="text" className='form-control p-2' style={{width:'60px',border:'1px solid lightgray'}} />)
                        }
                    </div>
                </div>
                <div className='flex-column-center mt-4'>
                    <button style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold'}} className='btn btn-primary w-100' >Verify MPIN</button>
                    <div className='mt-2 d-flex align-items-center' style={{fontSize:'12px'}}>Forgot MPIN? <a href="#" className='d-flex align-items-center' style={{pointerEvents:'none',marginLeft:'5px'}}>Reset Now <ArrowForwardIosIcon style={{width:'10px'}}/> </a></div>
                </div>
        
        </div>
        </div>
        <div className='flex-column-center mt-5'>
            <img src={preLogin.enterMpin.mpinBannerImage || verticalCard} height='261px'
                  width='165px'
                  alt='Alternate Text '
                  style={{borderRadius:"12px"}}/>
        </div>
    </div>
  )
}

export default EnterMpin