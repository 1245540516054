import React from 'react'
import { Header } from './core/Header/Header'
import accountClosed from '../assets/images/ServerError.svg'
import { useSelector } from 'react-redux'
import { DEFAULT_APP_TEXT_COLOR, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../utils/defaultStyling'

function AccountClosed() {

    const [preLogin,colorSchema] = useSelector(state => [state.PreLogin,state.ColorSchema])
  return (
    <div>
        <Header headerTitle="Attention" />
        <div className='body container w-100' style={{position:'relative',height:'90vh',color: colorSchema.background.textColor || DEFAULT_APP_TEXT_COLOR}}>
            <div className='flex-column-center'>
                <div className='imageCon' style={{marginTop:'30%'}}>
                    <div className='d-flex align-items-center justify-content-center' style={{width:'150px',height:'150px',borderRadius:'100px',backgroundColor:'#F7F8FC'}}>
                        <img src={accountClosed} />
                    </div>
                </div>
                <div className='flex-column-center mt-3' style={{fontFamily:'Inter'}}>
                    <h4>Account Closed</h4>
                    <div style={{textAlign:'center'}}>{ preLogin?.accountClosed?.accountClosedDescText || "For further information, please contact Customer Care"}</div>
                    <div className='mt-3'>You can call us on:</div>
                    <div>{preLogin?.accountClosed?.accountClosedMoblieNo || "1800 123"}</div>
                    <div className='mt-3'>Or Email us:</div>
                    <div>{preLogin?.accountClosed?.accountClosedEmail || "care.mool@aubank.in"}</div>
                </div>
            </div>
            <div className='w-100' style={{position:'absolute',bottom:'0px'}}><button className='btn btn-primary' style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold',width:'94%'}}>Return Home</button></div>
        </div>
    </div>
  )
}

export default AccountClosed