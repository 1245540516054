import React from "react"
import banner from "../../assets/images/preLoaderTransparent.gif"
import bankDefaultIcon from "../../assets/images/bankDefaultIcon.svg"
import preLoaderBanner from "../../assets/images/preloaderBanner.svg"
import {preLoader} from "../../assets/images"

import HyperfaceLogo from "../../assets/images/HyperfaceLogo.svg"
import '../../utils/styles.css'
import { DEFAULT_PRIMARY_COLOR } from "../../utils/defaultStyling"
import { useSelector } from "react-redux"
const Preloader = () => {

  const [preLogin,colorSchema] = useSelector(state => [state.PreLogin,state.ColorSchema])

  return (
    <div
      className="flex-column-center" style={{color:DEFAULT_PRIMARY_COLOR,fontSize:'14px',minHeight:"700px"}}
    >
      <div className="" style={{marginTop:'25%'}}>
        <img width="300px" height="300px" src={preLogin.preLoaderScreen.preLoaderBannerImage || preLoader}></img>
      </div>
      <div className="flex-column-center mt-3">
        <div className="flex-column-center">
          {preLogin?.preLoaderScreen?.preLoaderBankName && <> <div className="">Establishing a secure link with</div>
            <div className="">{preLogin?.preLoaderScreen?.preLoaderBankName}</div> </>
        }
        </div>
        {preLogin.preLoaderScreen.preLoaderBankLogoImage && <div className="mt-2">
          <img style={{
            width: "200px",
            height: "200px"
          }} src={preLogin.preLoaderScreen.preLoaderBankLogoImage}/>
        </div>}
      </div>
      {preLogin.preLoaderScreen.preLoaderBankLogoImage && <><div className="flex-column-center mt-3">
        <div className="mb-2">Powered by</div>
        <img src={HyperfaceLogo}/>
      </div></>}
    </div>
  )
}

export default Preloader
