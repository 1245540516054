import React from 'react'
import { EmbeddedLink } from '../../core/EmbededLink'
import { DEFAULT_APP_TEXT_COLOR, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from '../../../utils/defaultStyling'
import { useSelector } from 'react-redux'

function Mpin({title}) {
    const [colorSchema, preLogin] = useSelector(state => [state.ColorSchema, state.PreLogin])
  return (
    <div className='p-4' style={{position:"relative",height:"95vh",color: colorSchema.background.textColor || DEFAULT_APP_TEXT_COLOR}}>
            <div className='p-4' style={{backgroundColor:"#FFF",boxShadow:"0px 0px 2px gray",borderRadius:'8px'}}>
                <h4 className='header'>
                    Setup a new MPIN
                </h4>
                <div>
                    <div className='pt-3'>Enter new MPIN</div>
                    <div className='input-container d-flex align-items-center justify-content-between pt-2'>
                        {
                            [1,2,3,4].map((el,index) => <input key={index} type="text" className='form-control p-2' style={{width:'60px',border:'1px solid lightgray'}} />)
                        }
                    </div>
                </div>
                <div>
                <div className='pt-3'>Confirm new MPIN</div>
                    <div className='input-container d-flex align-items-center justify-content-between pt-2'>
                        {
                            [1,2,3,4].map((el,index) => <input key={index} type="text" className='form-control p-2 ' style={{width:'60px',border:'1px solid lightgray'}} />)
                        }
                    </div>
                </div>
            </div>
            <div className='p-4 w-100' style={{position:"absolute",left:"0px",bottom:"0px"}}>
                { title === 'setMPIN' ? preLogin?.setupMpin?.isSetMpinTncEnabled && <div className='d-flex align-items-center' style={{paddingBottom:"8px"}}>
                    <input type="checkbox" readOnly style={{outline:preLogin?.setupMpin?.setMpinTncMandatory ? '2px solid red' : '',height:'22px',width:'25px'}} checked={preLogin?.setupMpin?.setMpinTncCheckedByDefault}/>
                    <div className='' style={{fontSize:'14px',marginLeft:'10px'}}><EmbeddedLink text={preLogin?.setupMpin?.setMpinTncText  || "I accept the Terms & Conditions and give consent to activate my Credit Card"} /></div>
                </div> : 
                    preLogin?.forgetMpin?.isSetMpinTncEnabledSubsequent && <div className='d-flex align-items-center' style={{paddingBottom:"8px"}}>
                    <input type="checkbox" readOnly style={{outline:( title !== 'changeMPIN' && preLogin?.forgetMpin?.setMpinTncMandatorySubsequent) ? '2px solid red' : '',height:'22px',width:'25px'}} checked={title !== 'changeMPIN' ? preLogin?.forgetMpin?.setMpinTncCheckedByDefaultSubsequent : false}/>
                    <div className='' style={{fontSize:'14px',marginLeft:'10px'}}><EmbeddedLink text={preLogin?.forgetMpin?.setMpinTncTextSubsequent  || "I accept the Terms & Conditions and give consent to activate my Credit Card"} /></div>
                </div>
                }
                <button style={{backgroundColor: colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR, color: colorSchema.cta.secondaryColor || DEFAULT_SECONDARY_COLOR, border: "solid", borderWidth: "2px", borderColor:colorSchema.cta.primaryColor || DEFAULT_PRIMARY_COLOR,fontSize:'14px',fontWeight:'bold'}} className='btn btn-primary w-100'>Submit</button>
            </div>

        </div>
  )
}

export default Mpin