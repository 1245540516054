import { SET_PRE_LOGIN } from "../ActionTypes";

const initialState = {
    preLoaderScreen:{
        preLoaderBankName: "",
        preLoaderTimeInSec: "2",
        preLoaderBannerImage:"",
        preLoaderBankLogoImage:"",
        isCVPEnabled: false,
    },
    mpinWelcomeScreen:{
        setMpinIntroTitle1:"",
        setMpinIntroTitle2:"",
        setMpinIntroParagraph1:"",
        setMpinIntroParagraph2:"",
        setMpinIntroSteps:"",
        setMpinIntroImage:"",
        setMpinSuccessImage:""
    },
    setupMpin:{
        isSetMpinTncEnabled:false,
        setMpinTncMandatory:false,
        setMpinTncCheckedByDefault:false,
        setMpinTncAction:"",
        setMpinTncText:""
    },
    enterMpin:{
        mpinBannerImage:""
    },
    forgetMpin:{
        isSetMpinTncEnabledSubsequent:false,
        setMpinTncMandatorySubsequent:false,
        setMpinTncCheckedByDefaultSubsequent:false,
        setMpinTncActionSubsequent:"",
        setMpinTncTextSubsequent:""
    },
    accountClosed:{
        accountClosedDescText:"",
        accountClosedMoblieNo:"",
        accountClosedEmail:""
    }
}

const PreLoginReducer = (state=initialState, action) =>{
    switch(action.type){
        case SET_PRE_LOGIN:
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default PreLoginReducer